@tailwind base;
@tailwind components;
@tailwind utilities;
@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@200&display=swap");

html {
	scroll-behavior: smooth;
}

body {
	margin: 0;
	font-family: "Poppins", sans-serif;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}

code {
	font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
		monospace;
}

[data-theme="light"] .navLink {
	background: linear-gradient(
			to right,
			rgb(209 213 219 / var(--tw-bg-opacity)),
			rgb(209 213 219 / var(--tw-bg-opacity))
		),
		linear-gradient(
			to right,
			rgb(107 114 128 / var(--tw-bg-opacity)),
			rgb(75 85 99 / var(--tw-bg-opacity)),
			rgb(55 65 81 / var(--tw-bg-opacity))
		);
	background-size: 100% 0.1em, 0 0.1em;
	background-position: 100% 100%, 0 100%;
	background-repeat: no-repeat;
	transition: background-size 400ms;
}

[data-theme="dark"] .navLink {
	background: linear-gradient(
			to right,
			rgb(107 114 128 / var(--tw-bg-opacity)),
			rgb(107 114 128 / var(--tw-bg-opacity))
		),
		linear-gradient(
			to right,
			rgb(156 163 175 / var(--tw-bg-opacity)),
			rgb(209 213 219 / var(--tw-bg-opacity)),
			rgb(229 231 235 / var(--tw-bg-opacity))
		);
	background-size: 100% 0.1em, 0 0.1em;
	background-position: 100% 100%, 0 100%;
	background-repeat: no-repeat;
	transition: background-size 400ms;
}

.navLink:hover,
.navLink:focus {
	background-size: 0 0.1em, 100% 0.1em;
}

* {
	scrollbar-width: auto;
	scrollbar-color: #b5b5b5 #ffffff;
}

/* Chrome, Edge, and Safari */
*::-webkit-scrollbar {
	width: 8px;
}

[data-theme="dark"] *::-webkit-scrollbar-track {
	background: #ffffff00;
}

[data-theme="light"] *::-webkit-scrollbar-track {
	background: #ffffff;
}

*::-webkit-scrollbar-thumb {
	background-color: #b5b5b5;
	border-radius: 10px;
}
